import React, {Component} from 'react';
import FrontFooter from './common/Front-Footer';
import FrontWrapper from './common/FrontWrapper';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import _ from 'lodash'
import LazyImage from '../common/lazyImage';

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            currPage: 1,
            viewMore: false,
            image: [],
            hideSlide: false
        };
    }

    viewMore = () => {
        this.setState({
            viewMore: true
        });
    };

    openImageModal = (imageUrl, image, imageArry) => {
        // this.props.setLoader(true);
        let defaultImage = window.location.origin + '/static/media/compare-hk-icon.72656fd4.png';
        let tempArry = [];
        if (imageArry.length) {
            // eslint-disable-next-line array-callback-return
            imageArry.map((val, index) => {
                if (val !== image) {
                    tempArry.push({ url: val, type: 'photo', altTag: '' });
                }
            });
        }
        else {
            tempArry.push({ url: image, type: 'photo', altTag: '' });
        }
        if (image) {
            tempArry.splice(0, 0, { url: image, type: 'photo', altTag: '' });
        }

        var getScrollbody = document.querySelectorAll('.scroll-content-wrapper');
        if (getScrollbody && getScrollbody.length) {
            getScrollbody = getScrollbody[0];
            getScrollbody.classList.remove('js-scroll');
        }
        document.body.style.overflow = 'hidden !important';
        document.body.style.overflowY = 'hidden !important';
        document.body.classList.add('overflow-y-hidden');
        console.log(tempArry)
        this.setState({
            openEvent: true,
            image: tempArry,
            hideSlide: tempArry.length === 1 ? true : false
        });
    };
    closePopUp = () => {
        setTimeout(function () {
            document.body.classList.remove('overflow-y-hidden');
        });

        this.setState({
            openEvent: false
        });
        document.body.style.overflow = 'auto !important';
        document.body.style.overflowY = 'auto !important';
    };
    render() {
        const { fields } = this.props;
        console.log(this.state.openEvent)
        return (
            <FrontWrapper location={this.props.location} isForDevice={this.props.isForDevice}>
                <div
                    id='scroll-content'
                    className='scroll-content-wrapper'
                    data-scrollbar
                    ref={ref => (this.mainDiv = ref)}>
                    <div className='page-main page-current'>
                        <div className='page-toload legacy-page' data-bodyclassname='legacy'>
                            <main className='page-content' role='main'>
                                <section className='section-container our-story our-service-bn csr-container'>
                                    <div style={{ margin: 'auto' }} className='pad-lr-xs-20'>
                                        <h2 className='show-text head padd-max js-type-animation-alt'>{fields?.title}</h2>
                                        <p>
                                            {fields?.description}
                                        </p>
                                    </div>
                                    <div className='svgBottomFix' style={{ textAlign: '-webkit-center' }}>
                                        <img src={require('../../../static/assets/svg/index/down-arrow.svg')} alt='' />
                                    </div>
                                </section>

                                {
                                    !_.isEmpty(fields?.lists) ?
                                        <section
                                            ref={ref => (this.newsSection = ref)}
                                            className='csr-listing pad-lr-xs-20 mar-b-xs-0 pad-b-xs-0 mb-0'>
                                            <div className='container'>
                                                <div className='row csr-new-block'>
                                                    {/* eslint-disable-next-line array-callback-return */}
                                                    {fields?.lists?.map((data, i) => {
                                                        if (this.state.viewMore ? i < this.state.total : i < 9) {
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    onClick={() =>
                                                                        this.openImageModal(
                                                                            data.img.publicURL,
                                                                            data.img.publicURL,
                                                                            fields?.lists?.map(({img}) => img.publicURL)
                                                                        )
                                                                    }
                                                                    className='columns xxlarge-5 small-8 xsmall-16'>
                                                                    <div className='csr-list m-b-60'>
                                                                        <div className='csr-img margin-bottom-25'>
                                                                            {data && data.img.publicURL !== '' ? (
                                                                           
                                                                                <LazyImage
                                                                                    src={data.img.publicURL}
                                                                                    alt=''
                                                                                />
                                                                            ) : (
                                                                                    <LazyImage
                                                                                        src={require('../../../static/assets/img/compare-hk-icon.png')}
                                                                                        alt=''
                                                                                    />
                                                                                )}
                                                                        </div>
                                                                        <div className='csr-title margin-bottom-25'>
                                                                            {data.title}
                                                                        </div>
                                                                        <div className='csr-content'>
                                                                            {data.description}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                                {this.state.total > 9 && !this.state.viewMore ? (
                                                    <div className='view-more text-center' onClick={this.viewMore}>
                                                        {/* eslint-disable-next-line */}
                                                        <a className='btn btn-assent white-text'>View More</a>
                                                    </div>
                                                ) : (
                                                        ''
                                                    )}
                                            </div>
                                        </section> :
                                        <div className="no-data-bg-img">
                                            <h1>No recent news & media to show.</h1>
                                        </div>

                                }

                                {!this.props.isForDevice && <FrontFooter />}

                            </main>
                            <div className='preload' aria-hidden='true' />
                        </div>
                    </div>
                    <div className='page-main page-next' aria-hidden='true' />
                    <div className='page-main page-prev' aria-hidden='true' />
                    <footer className='footer' role='contentinfo' />
                    {/*<SEO url={this.props.location.pathname.split('/')[1]} />*/}
                </div>

                {this.state.openEvent && (
                    <div
                        className='imgbox csr-img-popup'
                        id={this.state.hideSlide ? 'csr-slide-display' : ''}>
                        {/*{setTimeout(() => {*/}
                        {/*    this.props.setLoader(false);*/}
                        {/*}, 3000)}*/}
                        {
                            (window.onkeydown = event => {
                                if (event.keyCode === 27) {
                                    this.closePopUp();
                                }
                            })
                        }
                        <ReactImageVideoLightbox
                            data={this.state.image}
                            onCloseCallback={() => {
                                document.body.classList.remove('overflow-y-hidden');
                                this.setState({
                                    openEvent: false,
                                    url: '',
                                    image: [],
                                    hideSlide: false
                                });
                            }}
                            startIndex={0}
                        />
                    </div>
                )}
            </FrontWrapper>
        );
    }
}
export default News
