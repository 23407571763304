import React from "react";
import {graphql} from "gatsby";
import News from "../components/FrontStatic/News";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const NewsMedia = (props) =>{
    return(
        <React.Fragment>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <News {...props} fields={props?.data?.[props.commonData.locale.locale]?.frontmatter} />
        </React.Fragment>
    )
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(NewsMedia);
export const query = graphql`
fragment NewsMediaFragment on MarkdownRemark {
     frontmatter {
       title
      description
      lists{
        title
        description
        img{
            publicURL
        }
      }
    }
  }
  query NewsPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "news-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  en: markdownRemark(frontmatter: {templateKey: {eq: "en-news"}}) {
       ...NewsMediaFragment
  }
  de: markdownRemark(frontmatter: {templateKey: {eq: "de-news"}}) {
       ...NewsMediaFragment
  }
  fr: markdownRemark(frontmatter: {templateKey: {eq: "fr-news"}}) {
       ...NewsMediaFragment
  }
  es: markdownRemark(frontmatter: {templateKey: {eq: "es-news"}}) {
       ...NewsMediaFragment
  }
  he: markdownRemark(frontmatter: {templateKey: {eq: "he-news"}}) {
       ...NewsMediaFragment
  }
  it: markdownRemark(frontmatter: {templateKey: {eq: "it-news"}}) {
       ...NewsMediaFragment
  }
  ja: markdownRemark(frontmatter: {templateKey: {eq: "ja-news"}}) {
       ...NewsMediaFragment
  }
  zh: markdownRemark(frontmatter: {templateKey: {eq: "zh-news"}}) {
       ...NewsMediaFragment
  }
 }
`